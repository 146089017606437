(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/common/assets/javascripts/payable.js') >= 0) return;  svs.modules.push('/components/payment/common/assets/javascripts/payable.js');

'use strict';

function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const {
  nanoid
} = svs.components.lbUtils;
const {
  NotImplementedError
} = svs.components.payment.common.error;

var _localId = new WeakMap();
class Payable {
  constructor() {
    _classPrivateFieldInitSpec(this, _localId, nanoid());
  }
  get localId() {
    return _classPrivateFieldGet(_localId, this);
  }

  get price() {
    throw new NotImplementedError();
  }

  get type() {
    throw new NotImplementedError();
  }

  get count() {
    return 1;
  }

  toJSON() {
    throw new NotImplementedError();
  }

  toString() {
    throw new NotImplementedError();
  }
}
setGlobal('svs.components.payment.common.Payable', Payable);

 })(window);